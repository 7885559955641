import { inject, Injectable } from '@angular/core';

import { environment } from '@env/environment';

import { HttpHelperService } from '@services/http-helper/http-helper.service';

import { DestroyedDrugRecord } from './models/DestroyedDrugRecord';
import { GlobalRecord } from './models/GlobalRecord';
import { DispensedDrugRecord } from './models/DispensedDrugRecord';

@Injectable({
  providedIn: 'root'
})
export class PharmacyDemoService {
  private apiUrl = `${environment.apiUrl}v1/DemoPharmacy/`;

  private httpHelpers = inject(HttpHelperService);

  constructor() {}

  public GlobalRecords() {
    return this.httpHelpers.get<GlobalRecord[]>(`${this.apiUrl}Global`);
  }

  public DispensedRecords() {
    return this.httpHelpers.get<DispensedDrugRecord[]>(`${this.apiUrl}Dispensed`);
  }

  public DestroyedRecords() {
    return this.httpHelpers.get<DestroyedDrugRecord[]>(`${this.apiUrl}Destroyed`);
  }
}
